/*------------------------------------------------------------------

Project        :   Pinlist – Directory, Classifieds and Jobs Multipurpose Bootstrap5 HTML5 Listing Template
Version        :   V.1.0
Create Date    :   02/06/22
Copyright      :   Spruko Technologies Private Limited 
Author         :   SPRUKO™
Author URL     :   https://themeforest.net/user/spruko
Support	       :   support@spruko.com
License        :   http://themeforest.net/licenses/standard

-------------------------------------------------------------------*/

/* ###### Admin Dashboard ####### */
@import "variables";
@import "admin/admin-custom";
@import "admin/sidemenu";